import "./Pcard.css";
import { PlanButton } from "../shared/buttons";
import { Link } from "react-router-dom";

const Pcard = (props) => {
	console.log("props", props);
	return (
		<div className="div1">
			{/* {DataCards?.map((data, index) => { */}
			{/* return ( */}
			<>
				<div className="overall">
					<div
						className="mrectangle"
						style={{ backgroundColor: props.backgroundColor }}>
						<div className="minner-rectangle">
							<div className="mcard-wrapper-feature">
								<div className="mrectangle-heading">{props.title}</div>
							</div>
							<div className="mcard-subtitle">
								<p>{props.description}</p>
							</div>
							<div className="mcard-price">
								<p>{props.price}</p>
							</div>
							<div className="mcard-period">
								<p>{props.period}</p>
							</div>

							<div className="src1">
								<img src={props.mimage} alt="" srcSet="" />
								<ul className="bullet">
									<li>
										<p className="mafter-img">{props.text}</p>
									</li>
									<li>
										<p className="mafter-img">{props.text1}</p>
									</li>
									<li>
										<p className="mafter-img">{props.text2}</p>
									</li>
									<li>
										<p className="mafter-img">{props.text3}</p>
									</li>
								</ul>
							</div>

							<div className="mplanbtn">
								<Link to="/checkoutpage2">
									<PlanButton title="Select Plan" />
								</Link>
							</div>
						</div>
						{/* <div className="" >Make your unique presence using our alphanumeric Sender ID feature.</div> */}
					</div>
				</div>
			</>
			{/*     */}
		</div>
	);
};

export default Pcard;
