import Price from "../components/Pricing/Pricing.jsx";
import Layout from "../components/shared/layout.js";
// import { Helmet } from "react-helmet";

export default function PricePage() {
	return (
		<Layout>
			<Price />
		</Layout>
	);
}

{
	/* <Helmet>
				<meta charSet="utf-8" />
				<title>Nex SMS</title>
				<meta name="description" content="Helmet application" />
				<meta
					name="keywords"
					content="Bulk SMS Solutions forBusinesses around the World"
				/>
				<meta property="og:site_name" content="NexSMS | Book Demo" />
				<meta property="og:type" content="website" />
				<meta name="sitecode" content="PK" />
				<meta name="author" content="Shahid Islam" />
				<meta property="og:url" content="https://www.NexSMS.io" />
				<meta
					property="og:description"
					content="Rely on the leading bulk sms marketing & alerts
platform for sending faster & more affordable sms
service campaigns & alerts."
				/>
			</Helmet> */
}
