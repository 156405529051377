// import Sidebar from "../components/sidebar/sidebar";
// import Navbar from "../components/navbar/navbar";
// import { useContext } from "react";
// import { AppContext } from "../Theme";
import Checkout2 from "../components/checkout2/checkout2";

export default function Checkoutpage2() {
	// const { darkmode, toggleMode } = useContext(AppContext);
	return (
		<>
			{/* <div className="sidebar-component">
				<Sidebar mode={darkmode} toggleMode={toggleMode} />
			</div>
			<div className="navbar-component">
				<Navbar username="Nexcare" mode={darkmode} />
			</div> */}
			<Checkout2 />
		</>
	);
}
