import NXCard from "../NxCard/NxCard";
import "./Feature.css";
import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";


import { PrimaryButton, SecondaryButton } from "../shared/buttons";

const Feature = () => {
	useEffect(() => {
		Aos.init({ duration: 2000 });
	}, []);
	const featureDataCards = [
		{
			title: "Personalized Sender ID",
			description:
				"Make your unique presence using our alphanumeric Sender ID feature.",
			image: "images/Vector.png",
		},
		{
			title: "Notifications & Alerts",
			description:
				"Using our API, you can easily communicate with a large number of customers.",
			image: "images/Vector3.png",
		},
		{
			title: "Real Time Reports",
			description:
				"Check the delivery status of SMS. Else check Analytical reports on our Web Panel.",
			image: "images/Vector5.png",
		},
		{
			title: "Two Factor Authentication",
			description: "Make the process safe and secure by employing 2FA.",
			image: "images/Vector1.png",
		},
		{
			title: "Web Panel",
			description:
				" Our Easy to use Web Panel allows you to send SMS to contacts in an Excel File.",
			image: "images/Vector2.png",
		},
		{
			title: "Scheduled Campaigns",
			description:
				"Schedule your campaigns without having to worry about their delivery.",
			image: "images/Vector6.png",
		},
	];
	return (
		<>
			<div className="container2">
				<div className="inner-rectangle1">
					<div className="featuresheading">
						<h2>Features</h2>
					</div>

					{/* <NXCard /> */}
					{/* <NXCard /> */}
					<div className="main">
						{featureDataCards?.map((data, index) => {
							return (
								<>
									{" "}
									<div style={{ margin: "0 0 50px 0" }} key={index}>
										<NXCard
											data-aos="fade-up"
											title={data.title}
											description={data.description}
											image={data.image}
										/>
									</div>
								</>
							);
						})}
					</div>
					<div id="but" className="btn-cont">
						<div className="bt1">
							<a href="/book-demo">
								<SecondaryButton title="Book Demo" />
							</a>
						</div>

						<p>OR</p>
						<div className="bt2">
							<a href="/authenticate">
								<PrimaryButton title="Free Trial" />
							</a>
						</div>
					</div>
					{/* <div style={{display:'flex',justifyContent:'center'}}>
            <div>
            <SecondaryButton title='Book Demo'  />
            </div>
            <div>
            <PrimaryButton title='Free Trial' />
            </div>
          </div> */}
				</div>
			</div>
		</>
	);
};

export default Feature;
