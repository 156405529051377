import "./bookdemo-head.css";

export default function BookDemoHead() {
	return (
		<div className="bookdemo-head">
			<div className="head1">
				<h1 className="heading-1">
					Book your <code>free demo.</code>
				</h1>
				<p className="sub-head">
					We really encourage you to see how NexSMS lets you use SMS as a
					communication channel.
				</p>
			</div>
			<div className="head2">
				<div className="con1">
					<div className="src1">
						<img src="images/blue-tick.png" alt="" srcSet="" />
						<p className="after-img">
							Enjoy a personalized guided tour, not a group webinar
						</p>
					</div>
					<div className="src2">
						<img src="images/green-tick.png" alt="" srcSet="" />
						<p className="after-img">
							See how NexSMS simplifies communicating using SMS
						</p>
					</div>
				</div>
				<div className="con2">
					<div className="src3">
						<img src="images/pink-tick.png" alt="" srcSet="" />
						<p className="after-img">
							We’ll show you how NexSMS helps you to grow your business
						</p>
					</div>
					<div className="src4">
						<img src="images/yellow-tick.png" alt="" srcSet="" />
						<p className="after-img">
							Demos are given by experts on using NexSMS
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
