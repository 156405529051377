import "./radio.css";
import { useState } from "react";

export const PrimaryButton = (props) => {
	return (
		<>
			<button
				type={props.type}
				id={props.id}
				onClick={props.onClick}
				style={props.style}
				className="btn1">
				{props.title} 
			</button>
		</>
	);
};

export const SecondaryButton = (props) => {
	return (
		<>
			<button
				type={props.type}
				id={props.id}
				onClick={props.onClick}
				style={props.style}
				className="btn2">
				{props.title}
			</button>
		</>
	);
};

export const PlanButton = (props) => {
	return (
		<>
			<button
				type={props.type}
				id={props.id}
				onClick={props.onClick}
				style={{ backgroundColor: props.backgroundColor1 }}
				className="mbtn">
				{props.title}
			</button>
		</>
	);
};

export const TwoButtons = (props) => {
	return (
		<>
			<a href="/book-demo">
				<SecondaryButton
					title="Book Demo"
					style={{ marginLeft: props.marginLeft ?? "30px" }}
				/>
			</a>

			<a href="/authenticate">
				<PrimaryButton title="Free Trial" />
			</a>
		</>
	);
};

export const SocialIcons = () => {
	return (
		<div className="socialicons">
			<img className="facebook" src="images/facebook.png" alt="" srcSet="" />
			<img className="twitter" src="images/twitter.png" alt="" srcSet="" />
			<img className="linkedin" src="images/linkedin.png" alt="" srcSet="" />
			<img className="youtube" src="images/youtube.png" alt="" srcSet="" />
			<img className="insta" src="images/insta.png" alt="" srcSet="" />
		</div>
	);
};

export const GoogleButton = () => {
	return (
		<div className="uz-container">
			<a href="/">
				<div className="g-sign-in-button">
					<div className="content-wrapper">
						<div className="logo-wrapper">
							<img src="images/g-logo.png" alt="img"></img>
						</div>
						<span className="text-container">
							<span>Sign in with Google</span>
						</span>
					</div>
				</div>
			</a>
		</div>
	);
};

export const SelectMenu = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState("Select your option");
	const options = [
		{ icon: "bx bxl-github", text: "Github" },
		{ icon: "bx bxl-instagram-alt", text: "Instagram" },
		{ icon: "bx bxl-linkedin-square", text: "Linkedin" },
		{ icon: "bx bxl-facebook-circle", text: "Facebook" },
		{ icon: "bx bxl-twitter", text: "Twitter" },
	];

	const handleOptionClick = (optionText) => {
		setSelectedOption(optionText);
		setIsOpen(false);
	};

	return (
		<div className={`select-menu ${isOpen ? "active" : ""}`}>
			<div className="select-btn" onClick={() => setIsOpen(!isOpen)}>
				<span className="sBtn-text">{selectedOption}</span>
				<i className="bx bx-chevron-down"></i>
			</div>
			<ul className="options">
				{options.map((option, index) => (
					<li
						className="option"
						key={index}
						onClick={() => handleOptionClick(option.text)}>
						<i className={option.icon}></i>
						<span className="option-text">{option.text}</span>
					</li>
				))}
			</ul>
		</div>
	);
};
