import MainContent from "../components/main-content/maincontent.jsx";
import PartnerLogos from "../components/partnerlogos/partnerlogos.jsx";
import WhyUs from "../components/WhyUs/WhyUs.jsx";
import Feature from "../components/Feature/Feature.jsx";
import Engage from "../components/Engage/Engage.jsx";
import Layout from "../components/shared/layout.js";
import { Helmet } from "react-helmet";
import Card from "../components/Cards/Cards.jsx";

export default function Home() {
	return (
		<Layout>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Nex SMS</title>
				<meta name="description" content="Helmet application" />
				<meta
					name="keywords"
					content="Bulk SMS Solutions forBusinesses around the World"
				/>
				<meta property="og:site_name" content="NexSMS | Home" />
				<meta property="og:type" content="website" />
				<meta name="sitecode" content="PK" />
				<meta name="author" content="Shahid Islam" />
				<meta property="og:url" content="https://www.NexSMS.io" />
				<meta
					property="og:description"
					content="Rely on the leading bulk sms marketing & alerts
platform for sending faster & more affordable sms
service campaigns & alerts."
				/>
			</Helmet>
			<MainContent />
			<PartnerLogos />
			<WhyUs />
			<Feature />
			<Engage />
			<Card />
		</Layout>
	);
}
