import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
// import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "./bookdemo-form.css";
import { RadioButton  } from "../shared/radio";


export default function BookDemoForm() {
  // const navigate = useNavigate();

  const validationSchema = Yup.object({
    companysize: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    country: Yup.string().required('Required'),
    language: Yup.string().required('Required'),
    demotype: Yup.string().required('Required'),
    demotime: Yup.string().required('Required'),
  });
  function handleSubmit(values) {
    console.log('form values:',values)
    
  }

  return (
    <div className="parentform">
      <div className="msg">
        <img className="logo_demo_page" src="images/logo.png" alt="" />
        <div className="con">
          <h1 id="heading">NexSMS Demo</h1>
          <p id="sub-head">Please answer the following questions<br />so we can tailor our demo to suit your<br />requirements.</p>
        </div>
      </div>

      <Formik
        initialValues={{
          companysize: '',
          email: '',
          country: '',
          language: '',
          demotype: '',
          demotime: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form className="form">
            <div className='feild1'>
              <p>Company Size*</p>
              <Field
                as="select"
                name="companysize"
                className={`companysize ${errors.companysize && touched.companysize ? 'error' : ''}`}
                placeholder="Your Company Size"
              >
                <option value="">Select...</option>
                <option value="small">Small</option>
                <option value="medium">Medium</option>
                <option value="large">Large</option>
              </Field>
              
            </div>

            <div className='uz-f'>
              <p>Business Email*</p>
              <Field
                type="email"
                name="email"
                className={`email ${errors.email && touched.email ? 'error' : ''}`}
                placeholder="Your Business Email"
              />
              
            </div>

            <div className='uz-2'>
              <p>Phone Number  (Optional)</p>

                  <PhoneInput
                    
                    inputClass="my-custom-input"
                    country={'pk'}
                    name="phonenumber"
                  />

             
            </div>

            <div className='feild2'>
              <p>Country*</p>
              <Field
                as="select"
                name="country"
                className={`select-country ${errors.country && touched.country ? 'error' : ''}`}
                placeholder="Your Country"
              >
                <option value="">Select...</option>
                <option value="usa">USA</option>
                <option value="uk">UK</option>
                <option value="canada">Canada</option>
              </Field>
              
            </div>

            <div className='feild3'>
              <p>Language*</p>
              <Field
                as="select"
                name="language"
                className={`language ${errors.language && touched.language ? 'error' : ''}`}
                placeholder="Your Language"
              >
                <option value="">Select...</option>
                <option value="english">English</option>
                <option value="spanish">Spanish</option>
                <option value="french">French</option>
              </Field>
             
            </div>

            <div className='feild4'>
              <p>What demo are you looking for?*</p>
              <Field
                as="select"
                name="demotype"
                className={`demotype ${errors.demotype && touched.demotype ? 'error' : ''}`}
                placeholder="DemoType"
              >
                <option value="">Select...</option>
                <option value="product">Product Demo</option>
                <option value="service">Service Demo</option>
                <option value="custom">Custom Demo</option>
              </Field>
              
            </div>

            <div className='feild5'>
              <p>Preferred Demo Time*</p>
              <Field
                as="select"
                name="demotime"
                className={`demotime ${errors.demotime && touched.demotime ? 'error' : ''}`}
                placeholder="DemoTime"
              >
                <option value="">Select...</option>
                <option value="morning">Morning</option>
                <option value="afternoon">Afternoon</option>
                <option value="evening">Evening</option>
              </Field>
             
            </div>

            <div className="feild6">
              <p id="question">Have You Ever Used SMS API Before?*</p>
              <div className="ques">
                <div className="rdbtn">
                <RadioButton/>
                </div>
              </div>
            </div>

            <button className="submit-btn" onSubmit={handleSubmit} type="submit">Submit</button>
          </Form>
        )}
      </Formik>

    </div>
  );
}
