import "./WhyUs.css";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
const WhyUs = () => {
	useEffect(() => {
		Aos.init({ duration: 2000, once: "True" });
	}, []);
	const whyUsDataCards = [
		{
			id: 1,
			title: "Unbeatable Rates and Delivery Speeds",
			description:
				"Automated SMS gateway routing and State of Art infrastructure ensure higher delivery rates",
			image: "images/brk 2.png",
		},
		{
			id: 2,
			title: "Messaging Quality",
			description:
				"We use technologies that help us to exceed the benchmarks of messaging quality, regardless of the geographical location of the User",
			image: "images/ups 1.png",
		},
		{
			id: 3,
			title: "Fanatic Customer Support",
			description:
				"You can reach us round the clock, and we will be happy to assist you. NexSMS’s customer support team is available 24/7.",
			image: "images/sscale 1.png",
		},
		{
			id: 4,
			title: "Smart Tracking",
			description:
				" Our system gives you choice to keep a track of anything and everything. You can keep a check on sent SMS logs to delivery time to frequently failed numbers and more.",
			image: "images/chhnl 1.png",
		},
	];

	return (
		<div id="whyus">
			<div className="tagLine2">
				<h2 className="h2">Why NexSMS?</h2>
			</div>
			<br />
			<div className="tagLine">
				<strong>
					With Our Bulk SMS Service,<code> Stand Out </code> From the Rest in
					the Market
				</strong>
			</div>
			<div className="container3">
				{whyUsDataCards?.map((data, index) => {
					if (index % 2 === 0) {
						// Display a div after every two cards
						return (
							<>
								<div className="card-container">
									<div data-aos="fade-in" className="card">
										<div className="img-box">
											<img src={data.image} alt="" />
											<h2 className="heading1">{data.title}</h2>
											<p className="heading2">
												<br />
												{data.description}
											</p>
										</div>
									</div>
									{whyUsDataCards[index + 1] && (
										<div data-aos="fade-in" className="card">
											<div className="img-box">
												<img src={whyUsDataCards[index + 1].image} alt="" />
												<h2 className="heading1">
													{whyUsDataCards[index + 1].title}
												</h2>
												<p className="heading2">
													<br />
													{whyUsDataCards[index + 1].description}
												</p>
											</div>
										</div>
									)}
								</div>
							</>
						);
					}
					return null;
				})}
			</div>
		</div>
	);
};

export default WhyUs;
