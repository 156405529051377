import React from "react";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";

const layout = ({ children }) => {
	return (
		<>
			<Navbar />
			{children}
			<Footer />
		</>
	);
};

export default layout;
