import "./signuplogin.css";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
	PrimaryButton,
	SecondaryButton,
	GoogleButton,
} from "../shared/buttons";

export default function LoginSignup() {
	useEffect(() => {
		const sign_in_btn = document.querySelector("#sign-in-btn");
		const sign_up_btn = document.querySelector("#sign-up-btn");
		const container = document.querySelector(".container");

		sign_up_btn.addEventListener("click", () => {
			container.classList.add("sign-up-mode");
		});

		sign_in_btn.addEventListener("click", () => {
			container.classList.remove("sign-up-mode");
		});
	}, []);

	const signInValidationSchema = Yup.object().shape({
		userName: Yup.string().required("Username is required"),
		password: Yup.string().required("Please enter your password"),
		// .matches(
		//   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
		//   "Must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character"
		// ),
	});

	const signUpValidationSchema = Yup.object().shape({
		userName: Yup.string().required("Username is required"),
		email: Yup.string()
			.email("Enter a valid email address")
			.required("Email is required"),
		password: Yup.string().required("Please enter your password"),
		// .matches(
		//   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
		//   "Must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character"
		// ),
	});

	const signInFormik = useFormik({
		initialValues: {
			userName: "",
			password: "",
		},
		validationSchema: signInValidationSchema,
		onSubmit: (values) => {
			console.log("Sign In Form Values:", values);
		},
	});

	const signUpFormik = useFormik({
		initialValues: {
			userName: "",
			email: "",
			password: "",
		},
		validationSchema: signUpValidationSchema,
		onSubmit: (values) => {
			console.log("Sign Up Form Values:", values);
		},
	});

	const handleSignInClick = () => {
		console.log("You pressed sign in");
	};

	const handleSignUpClick = () => {
		console.log("You pressed sign up");
	};

	return (
		<div className="container">
			<div className="forms-container">
				<div className="signin-signup">
					<form onSubmit={signInFormik.handleSubmit} className="sign-in-form">
						<h2 className="title">Sign in</h2>
						<div
							className={`input-field ${
								signInFormik.touched.userName && signInFormik.errors.userName
									? "error"
									: ""
							}`}>
							<i className="fas fa-user"></i>
							<input
								type="text"
								placeholder="Username"
								name="userName"
								value={signInFormik.values.userName}
								onBlur={signInFormik.handleBlur}
								onChange={signInFormik.handleChange}
							/>
						</div>

						<div
							className={`input-field ${
								signInFormik.touched.password && signInFormik.errors.password
									? "error"
									: ""
							}`}>
							<i className="fas fa-lock"></i>
							<input
								type="password"
								placeholder="Password"
								name="password"
								value={signInFormik.values.password}
								onBlur={signInFormik.handleBlur}
								onChange={signInFormik.handleChange}
							/>
						</div>

						<SecondaryButton
							id="bit"
							title="Sign In"
							style={{ marginLeft: "0px", marginRight: "30px" }}
							onClick={handleSignInClick}
							type="submit"
						/>
						<p className="social-text">OR</p>
						<GoogleButton />
					</form>
					<form onSubmit={signUpFormik.handleSubmit} className="sign-up-form">
						<h2 className="title">Sign up</h2>
						<div
							className={`input-field ${
								signUpFormik.touched.userName && signUpFormik.errors.userName
									? "error"
									: ""
							}`}>
							<i className="fas fa-user"></i>
							<input
								type="text"
								placeholder="Username"
								name="userName"
								value={signUpFormik.values.userName}
								onBlur={signUpFormik.handleBlur}
								onChange={signUpFormik.handleChange}
							/>
						</div>

						<div
							className={`input-field ${
								signUpFormik.touched.email && signUpFormik.errors.email
									? "error"
									: ""
							}`}>
							<i className="fas fa-envelope"></i>
							<input
								type="email"
								placeholder="Email"
								name="email"
								value={signUpFormik.values.email}
								onBlur={signUpFormik.handleBlur}
								onChange={signUpFormik.handleChange}
							/>
						</div>

						<div
							className={`input-field ${
								signUpFormik.touched.password && signUpFormik.errors.password
									? "error"
									: ""
							}`}>
							<i className="fas fa-lock"></i>
							<input
								type="password"
								placeholder="Password"
								name="password"
								value={signUpFormik.values.password}
								onBlur={signUpFormik.handleBlur}
								onChange={signUpFormik.handleChange}
							/>
						</div>

						<SecondaryButton
							id="bt"
							title="Sign up"
							style={{ marginLeft: "0px", marginRight: "30px" }}
							onClick={handleSignUpClick}
							type="submit"
						/>
						<p className="social-text">OR</p>
						<GoogleButton />
					</form>
				</div>
			</div>

			<div className="panels-container">
				<div className="panel left-panel">
					<div className="content">
						<h3>New here?</h3>
						<p id="uz-p">
							Sign Up & Get Free SMS Credits to Test NexSMS Platform
						</p>
						<button
							className="btn transparent"
							id="sign-up-btn"
							onClick={handleSignUpClick}>
							<PrimaryButton
								title="Sign up"
								style={{
									marginLeft: "-6px",
									// marginRight: "35px",
									marginTop: "-5px",
								}}
							/>
						</button>
					</div>
					<img
						src="images/undraw_my_app_re_gxtj.svg"
						className="image"
						alt=""
					/>
				</div>
				<div className="panel right-panel">
					<div className="content">
						<div className="main-content">
							<h3>One of us?</h3>
						</div>
						<p>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum
							laboriosam ad deleniti.
						</p>
						<button
							className="btn transparent"
							id="sign-in-btn"
							onClick={handleSignInClick}>
							<PrimaryButton
								title="Sign In"
								style={{
									marginLeft: "-5px",
									marginRight: "35px",
									marginTop: "-5px",
								}}
							/>
						</button>
					</div>
					<img
						src="images/undraw_access_account_re_8spm.svg"
						className="image"
						alt=""
					/>
				</div>
			</div>
		</div>
	);
}
