import React, { useState, useEffect } from "react";
import "./partnerlogos.css";

export default function PartnerLogos() {
	const [currentLogoIndex, setCurrentLogoIndex] = useState(0);
	const logos = [
		"./images/logo-rt.png",
		"./images/logo-nxsw.png",
		"./images/logo-nexcare.png",
	];
	useEffect(() => {
		if (window.innerWidth <= 857) {
			const interval = setInterval(() => {
				setCurrentLogoIndex((prevIndex) => (prevIndex + 1) % logos.length);
			}, 3000);

			return () => clearInterval(interval);
		}
	}, [logos.length]);

	if (window.innerWidth <= 857) {
		return (
			<div className="main-container">
				<center>
					<h1 className="heading">
						Trusted by the <code>fastest growing brands </code>in rapidly
						developing economies
					</h1>
				</center>
				<div className="for-marquee-after857">
					{logos.map((logo, index) => (
						<img
							key={index}
							className={`logo${index + 1}`}
							src={logo}
							alt=""
							srcSet=""
							style={{
								display: currentLogoIndex === index ? "block" : "none",
							}}
						/>
					))}
				</div>
			</div>
		);
	} else {
		return (
			<div className="main-container">
				<center>
					<h1 className="heading">
						Trusted by the <code>fastest growing brands </code>in rapidly
						developing economies
					</h1>
				</center>
				<div className="for-marquee">
					<img className="logo1" src="./images/logo-rt.png" alt="" srcSet="" />
					<img
						className="logo2"
						src="./images/logo-nxsw.png"
						alt=""
						srcSet=""
					/>
					<img
						className="logo3"
						src="./images/logo-nexcare.png"
						alt=""
						srcSet=""
					/>
					<img className="logo4" src="./images/pre1.png" alt="" srcSet="" />
					<img className="logo5" src="./images/future1.png" alt="" srcSet="" />
				</div>
			</div>
		);
	}
}
