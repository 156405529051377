import Pcard from "../Pcard/Pcard";
import "./Pricing.css";

const Price = () => {
	const Pdatacard = [
		{
			title: "Trial",
			description: "Just a Test!",

			price: "0 PKR",
			period: "per month",
			text: "30 SMS",
			text1: "30 days Time Period",
			text2: "--",
			text3: "--",
			mimage: "images/blue-tick.png",
			backgroundColor: "#FCE7FF",
			backgroundColor2: "#F9B4FF",
		},
		{
			title: "Starter",
			description: "For Taking Things to the next level!",

			price: "9,400 PKR",
			period: "per month",
			text: "4,000 SMS",
			text1: "30 days Time Period",
			text2: "Transactional/Promotional",
			mimage: "images/blue-tick.png",
			text3: "2.8 PKR/message",
			backgroundColor2: "#51C3FE",
		},
		{
			title: "Basic",
			description: "Recommended for most Businesses!",

			price: "23,000 PKR",
			period: "per month",
			text: "10,000 SMS",
			text1: "30 days Time Period",
			text2: "Transactional/Promotional",
			mimage: "images/blue-tick.png",
			text3: "2.8 PKR/message",
			backgroundColor: "#FEF8D7",
			backgroundColor2: "#FCE047",
		},
		{
			title: "Standard",
			description: "Your Elite SMS Experience!",

			price: "70,000 PKR",
			period: "per month",
			text: "30,000 SMS",
			text3: "2.8 PKR/message",
			text1: "45 days Time Period",
			text2: "Transactional/Promotional",
			mimage: "images/blue-tick.png",
			backgroundColor: "#C6FFE7",
			backgroundColor2: "#00E785",
		},
	];
	return (
		<>
			<div className="msubcontainer">
				<div className="priceheading">
					<h1>Get great features at a price that makes sense</h1>
				</div>
				<div className="pricesubheading">
					<h3>Affordable pricing with zero setup fees.</h3>
				</div>
			</div>
			<div className="mcontainerm">
				<div className="mmain">
					{Pdatacard?.map((data, index) => {
						return (
							<>
								{" "}
								<div style={{ margin: "0 0 50px 0" }} key={index}>
									<Pcard
										title={data.title}
										description={data.description}
										image={data.image}
										price={data.price}
										period={data.period}
										// mimage={data.mimage}
										text={data.text}
										text1={data.text1}
										text2={data.text2}
										text3={data.text3}
										backgroundColor={data.backgroundColor}
										backgroundColor2={data.backgroundColor2}
									/>
								</div>
							</>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default Price;
