import Home from "./pages/home.jsx";
import BookDemoPage from "./pages/bookdemo.jsx";
import LoginSignupPage from "./pages/auth.jsx";
import PricePage from "./pages/price.jsx";
import { Routes, Route } from "react-router-dom";
import "./styles.css";
import Checkoutpage2 from "./pages/checkoutpage2.jsx";
function App() {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/book-demo" element={<BookDemoPage />} />
			<Route path="/authenticate" element={<LoginSignupPage />} />
			<Route path="/price-page" element={<PricePage />} />
			<Route path="/checkoutpage2" element={<Checkoutpage2 />} />
		</Routes>
	);
}

export default App;
