import React, { useState } from "react";
// import { Form } from "react-router-dom"
import "./radio.css";

export const RadioButton = () => {
  const [, ] = useState({
    isAgree: false,
    gender: "",
  });

  return (
    <form>
      <div className="radiobtn">
        <label>Yes</label>
        <input type="radio" name="question" value="Yes" />

        <label>No</label>
        <input type="radio" name="question" value="No" />
      </div>
    </form>
  );
};
