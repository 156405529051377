import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "./navbar.css";

import { PrimaryButton, SecondaryButton } from "../shared/buttons";

function Navbar() {
	const navRef = useRef();

	const showNavbar = () => {
		navRef.current.classList.toggle("responsive_nav");
	};

	return (
		<div className="nav">
			<header>
				<div>
					<a href="/">
						<img className="logo" alt="Logo" src="images/logo.png" />
					</a>
				</div>
				<nav ref={navRef}>
					<img className="logo-2" alt="Logo" src="images/logo.png" />

					<a className="a" href="/price-page">
						Pricing
					</a>
					<a className="a" href="/">
						Solution
					</a>
					<a className="a" href="/">
						Integrations
					</a>
					<a className="a" href="/">
						Afiliate/Partners
					</a>
					<a className="a" href="/">
						Blogs
					</a>

					<div id="buty">
						<a href="/book-demo">
							<SecondaryButton title="Book Demo" />
						</a>
					</div>

					<a href="/authenticate">
						<PrimaryButton title="Free Trial" />
					</a>

					<button
						className="nav-btn nav-close-btn"
						style={{ width: "130px" }}
						onClick={showNavbar}>
						<FaTimes />
					</button>
				</nav>
				<button className="nav-btn" onClick={showNavbar}>
					<FaBars />
				</button>
			</header>
		</div>
	);
}

export default Navbar;
