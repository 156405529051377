import "./Engage.css";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

import { PrimaryButton, SecondaryButton } from "../shared/buttons";
const Engage = () => {
	useEffect(() => {
		Aos.init({ duration: 2000 });
	}, []);
	return (
		<>
			<div className="heading5">
				<span className="heading6"> Engage and grow.</span> With SMS Service,
				Stand Out From the Rest in the Market.
			</div>

			<div className="container1">
				<div data-aos="fade-up" className="card1">
					<div className="imgbx2">
						{/* <a href=""> */}
						<img src="images/Marketing 1.png" alt="" />
						{/* </a> */}
						<h2 className="heading3">Reseller</h2>
						<p className="heading4">
							<br />
							You can offer all the NexSMS features under your own branding
							which your customers will definitely Love.
						</p>
					</div>
				</div>
				<div data-aos="fade-up" className="card1">
					<div className="imgbx2">
						{/* <a href=""> */}
						<img src="images/sales 1.png" alt="" />
						{/* </a> */}
						<h2 className="heading3">Developers</h2>
						<p className="heading4">
							<br />
							With NexSMS APIs you can Send bulk SMS offers, trigger
							Transactional updates, Verify OTPs, Get inbound SMS, reports,
							Update contacts and more.
						</p>
					</div>
				</div>
				<div data-aos="fade-up" className="card1">
					<div className="imgbx2">
						{/* <a href=""> */}
						<img src="images/supprt 1.png" alt="" />
						{/* </a> */}
						<h2 className="heading3">Marketing Agencies</h2>
						<p className="heading4">
							<br />
							Explore the new revenue channel and deliver the ROI to your Client
							campaigns, Track Campaign metrics.
						</p>
					</div>
				</div>
			</div>

			<div data-aos="fade-in" className="rectangle">
				<div className="inner-rectangle">
					<div className="rectangle-heading">
						<span className="rectangle-heading-style">
							SMS Carrier Services.
						</span>{" "}
						<p style={{ display: "inline" }}>
							For aggregators, we provide you with the largest network of direct
							connectivity across the world.{" "}
						</p>
					</div>

					<div className="btn-cont">
						<div className="btn-cont1">
							<a href="/book-demo">
								<SecondaryButton id="uz-btn1" title="Book Demo" />
							</a>
						</div>

						<p>OR</p>
						<div className="btn-cont2">
							<a href="/authenticate">
								<PrimaryButton id="uz-btn2" title="Free Trial" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Engage;
