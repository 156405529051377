import "./footer.css";
import React, { useState } from 'react';

 export default function Footer(props) {
    const [DisplayOpt, setDisplayOpt] = useState('none');
    function handleLanguagesDropdown() {
        setDisplayOpt(prevDisplay => (prevDisplay === 'none' ? 'flex' : 'none'));
      }
      
 


    return (
   <div className="footer" id={props.id}>
    <footer>
    <div className="payments">
        <h2 className="pays">We Accept</h2>
        <img className="paypic" src="images/paypic.png" alt="paypic" srcSet="" />
        <h2 className="pays">We Are Everywhere...</h2>
    </div>
    <div className="link1">
        <ul className="link1-list">
            <h3 className="nexsms">NexSMS</h3>
            <li>Pricing</li>
            <li>Solutions</li>
            <li>Integrations</li>
            <li>Affiliate/Partners</li>
            <li>Blogs</li>
        </ul>
    </div>
    <div className="link2">
        <ul className="link2-list">
            <h3 className="resources">Resources</h3>
            <li>Blogs</li>
            <li>Customers</li>
            <li>Developer Docs</li>
            <li>Help Center</li>
            <li>Community</li>
        </ul>
    </div>
    <div className="link3">
        <ul className="link3-list">
            <h3 className="features">Top Features</h3>
            <li>Shared Team Inbox</li>
            <li>Bulk Messaging</li>
            <li>Custom Notifications</li>
            <li>No Code Chatbots</li>
            <li>Branding</li>
        </ul>
    </div>
    <div className="languages" onClick={handleLanguagesDropdown} >
        <img className="languageicon" src="images/languageicon.png" alt="" srcSet="" />
        <svg className="svg" xmlns="http://www.w3.org/2000/svg" width="19" height="8" viewBox="0 0 19 13" fill="none">
  <path d="M9.5 12.9979C9.5 12.9977 9.49991 12.9974 9.49974 12.9973L0.751031 4.2501C-0.21592 3.28332 -0.215735 1.71562 0.751444 0.749073C1.71814 -0.216996 3.28488 -0.21672 4.25124 0.749691L9.5 5.99876L14.7487 0.749794C15.7151 -0.216674 17.2819 -0.216951 18.2487 0.749176C19.2158 1.71571 19.2161 3.28334 18.2493 4.25021L9.50149 12.9985C9.50094 12.9991 9.5 12.9987 9.5 12.9979Z" fill="white"/>
</svg>
  <div className="uz-opt" style={{ display: DisplayOpt }} >

    <div className="l1">
    <p className="uz-subopt" >Arabic</p>
    </div>
    <div className="l1">
    <p className="uz-subopt" >English</p>
    </div>
    <div className="l1">
    <p className="uz-subopt" >Chinese</p>
    </div>

  </div>

        {/* <img className="vector" src="images/vector.png" alt="" srcSet="" /> */}
        {/* <div className="select-language">
            <select id="select"  >
            <option value="saab">ENGLISH</option>
            <option value="volvo">URDU</option>
            <option value="opel">HINDI</option>
            <option value="audi">CHINESE</option>
            <option value="audi">ARABIC</option>
            </select>
        </div> */}
    </div>
    </footer>
    
    <div className="footer-end">
        <h2 className="footerend-h" >© NexSMS 2023 | <span className="spn1" >Terms & Conditions</span>  | <span className="spn1">Privacy Policy</span> </h2>
        <div className="socialicons">
            <img className="facebook" src="images/facebook.png" alt="" srcSet="" />
            <img className="twitter" src="images/twitter.png" alt="" srcSet="" />
            <img className="linkedin" src="images/linkedin.png" alt="" srcSet="" /> 
            <img className="youtube" src="images/youtube.png" alt="" srcSet="" />
            <img className="insta" src="images/insta.png" alt="" srcSet="" />
        </div>
    </div>
   </div>
    );
}
