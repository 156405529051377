import { TwoButtons } from "../shared/buttons";
import "./maincontent.css";

export default function MainContent() {


	return (
		<div className="main-content">
			<div className="content">
				<div>
					<h1>
						Bulk <code>SMS Solutions</code> for
						<br />
						Businesses around the
						<br />
						World
					</h1>
				</div>
				<p>
					Rely on the leading bulk sms marketing & alerts
					<br />
					platform for sending faster & more affordable sms
					<br />
					service campaigns & alerts.
				</p>
				<div className="btns">
					<TwoButtons marginLeft='0px' />
				</div>
			</div>
			<img className="main-image" src="images/main.jpg" alt="" srcSet="" />
		</div>
	);
}

// export default MainContent;
