import "./NxCard.css";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const NxCard = (props) => {
	useEffect(() => {
		Aos.init({ duration: 2000 });
	}, []);
	console.log("props", props);
	return (
		<div className="div1">
			{/* {DataCards?.map((data, index) => { */}
			{/* return ( */}
			<>
				<div data-aos="fade-up" className="rectangle3">
					<div className="inner-rectangle3">
						<div className="card-wrapper-feature3">
							<div className="card-title3">
								<img className="img-2" src={props.image} alt="" />

								<div className="rectangle-heading3">{props.title}</div>
							</div>
						</div>
						<div className="card-subtitle3">
							<p>{props.description}</p>
						</div>
					</div>
					{/* <div className="" >Make your unique presence using our alphanumeric Sender ID feature.</div> */}
				</div>
			</>
			{/*     */}
		</div>
	);
};

export default NxCard;
